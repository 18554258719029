import * as React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'
import strategiesImg from '../assets/images/tradingwithhand.jpg'
import glossaryImg from '../assets/images/glossary.jpg'
import newspapersImg from '../assets/images/newspapers.jpg'
import trainingImg from '../assets/images/training.jpg'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class EdgeUcationPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Edge University'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    var edgeuitems = (
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Link to={`/edgeu-strategies`}>
            <Grid container spacing={0} className="edgeu_item_container">
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <img
                      className="edgeu_item_img"
                      alt=""
                      src={strategiesImg}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className="edgeu_item_h3">Strategies</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="edgeu_item_p">
                      Effective trading and investing strategies, tried and
                      tested in all market conditions and various security
                      types.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Link to={`/edgeu-glossary`}>
            <Grid container spacing={0} className="edgeu_item_container">
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <img className="edgeu_item_img" alt="" src={glossaryImg} />
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className="edgeu_item_h3">Glossary</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="edgeu_item_p">
                      A definitive list of market terms and definitions designed
                      so you can understand what is being discussed or written
                      about.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Link to={`/news`}>
            <Grid container spacing={0} className="edgeu_item_container">
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <img
                      className="edgeu_item_img"
                      alt=""
                      src={newspapersImg}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className="edgeu_item_h3">News</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="edgeu_item_p">
                      Frequently updated news articles about the market and
                      investing, including articles about updates on
                      EdgeForcaster, the advantage our subscribers receive and
                      other topics we find interesting.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Link to={`/edgeu-training`}>
            <Grid container spacing={0} className="edgeu_item_container">
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <img className="edgeu_item_img" alt="" src={trainingImg} />
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className="edgeu_item_h3">Training</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="edgeu_item_p">
                      Training and definitions for all the features available
                      through the EdgeForecaster platform and many more,
                      designed to improve the knowledge and skills of traders
                      around the world.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    )
    var edgeuitems_mob = (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Link to={`/edgeu-strategies`}>
            <Grid container spacing={0} className="edgeu_item_container">
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <img
                      className="edgeu_item_img"
                      alt=""
                      src={strategiesImg}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className="edgeu_item_h3">Strategies</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="edgeu_item_p">
                      Effective trading and investing strategies, tried and
                      tested in all market conditions and various security
                      types.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to={`/edgeu-glossary`}>
            <Grid container spacing={0} className="edgeu_item_container">
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <img className="edgeu_item_img" alt="" src={glossaryImg} />
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className="edgeu_item_h3">Glossary</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="edgeu_item_p">
                      A definitive list of market terms and definitions designed
                      so you can understand what is being discussed or written
                      about.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to={`/news`}>
            <Grid container spacing={0} className="edgeu_item_container">
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <img
                      className="edgeu_item_img"
                      alt=""
                      src={newspapersImg}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className="edgeu_item_h3">News</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="edgeu_item_p">
                      Frequently updated news articles about the market and
                      investing.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to={`/edgeu-training`}>
            <Grid container spacing={0} className="edgeu_item_container">
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <img className="edgeu_item_img" alt="" src={trainingImg} />
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className="edgeu_item_h3">Training</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="edgeu_item_p">
                      Training and definitions for the features in the
                      EdgeForecaster app.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    )

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <p>
                    Trading can be a difficult game. Market volatility,
                    directional biases, hidden commissions/margins and external
                    influences make each and every trading decision fraught with
                    potential losses. Technical analysis and market knowledge
                    constitutes about half of what is required for long term
                    trading success. The other half is made up of aspects like
                    psychology, risk/money management, mindset, expectations and
                    most importantly, market{' '}
                    <span
                      style={{
                        color: '#4648ff',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                      }}
                    >
                      {' '}
                      EDGE
                    </span>
                    .
                  </p>
                  <p>
                    Edge University (Edge-U) is a series of educational
                    articles, trading strategies, market glossaries,
                    EdgeForecaster feature training and all-round market
                    information. The team at EdgeForecaster, along with approved
                    submissions from the wider community, will continually
                    expand this knowledge base so it is a valuable resource for
                    traders and investors of all levels.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12}>
                {isMobile ? edgeuitems_mob : edgeuitems}
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(EdgeUcationPage)
export const EdgeUcationPageQuery = graphql`
  query EdgeUcationPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
